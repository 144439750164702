import React, { Component } from 'react'

import CyclePage from '../../containers/CyclePage'
import PrimaryLayout from '../../components/layouts/PrimaryLayout'
import pagesInfos from '../../config/i18n/pagesInfos'

const CycleAnnuel20242025 = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCyclesEtStagesCycleAnnuel20242025}
    >
      <CyclePage cycle="annuel-2024-2025" />
    </PrimaryLayout>
  )
}

export default CycleAnnuel20242025